import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import {Route, Switch, withRouter} from "react-router";
import {BrowserRouter} from "react-router-dom";
import routes from "./routes";
import IndexPage from "./pages/IndexPage";
import ErrorPage from "./pages/ErrorPage";
import CreatePage from "./pages/CreatePage";
import ResultPage from "./pages/ResultPage";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {hitEvent, hits, logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";

Sentry.init({
  environment: process.env.NODE_ENV,
  dsn: "https://52fae8f7f4c848c3a7c4b936918e00b4@o322608.ingest.sentry.io/5499553",
});

Sentry.setUser({token: window.clientConfig.token});

class App extends React.Component {

  componentDidMount() {
    const bodyClasses = [];
    bodyClasses.push("app-locale-" + window.clientConfig.locale);
    window.clientConfig.isWebview && bodyClasses.push("app-webview");
    window.clientConfig.isWebviewAndroid && bodyClasses.push("app-webview-android");
    window.clientConfig.isWebviewIOS && bodyClasses.push("app-webview-ios");
    window.clientConfig.isWeb && bodyClasses.push("app-web");
    window.document.body.classList.add(...bodyClasses);

    window.webviewHolders.tabSelected.subscribe((v) => {
      if (v > 0) {
        hitEvent(hits.WEBVIEW_TAB_FOCUS);
        logEvent(userEvents.WEBVIEW_TAB_FOCUS, {
          os: window.clientConfig.isWebviewAndroid
            ? "android"
            : (window.clientConfig.isWebviewIOS ? "ios" : "other")
        });
      }
    });

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      })
    }
  }

  render() {
    return (
      <div className={`${window.clientConfig.locale}`}>
        <Switch>
          <Route exact path={routes.INDEX} render={props => <IndexPage {...props} />} />
          <Route exact path={routes.ERROR} render={props => <ErrorPage {...props} />} />
          <Route exact path={routes.CREATE} render={props => <CreatePage {...props} />} />
          <Route path={routes.RESULT} render={props => <ResultPage {...props} />} />
        </Switch>
        <ModalWrapper />
      </div>
    );
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppWithRouter />
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
