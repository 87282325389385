module.exports = {
  "strings": {
    "try_another_photo": "Try another photo",

    "landing__title": "You Ken be anything. <br/> Even Barbie!",
    "landing__subtitle": "Just give your photo to AI 😜",
    "landing__text_1": "The results vary from creepy to somehow weirdly attractive.",
    "landing__text_2": "Sometimes dope, but rarely dull.",
    "landing__select_photo_button": "Upload photo",

    "processing_text_1": "Ken you wait a second please?",
    "processing_text_2": "Your trip to Costa del Doll is about to begin!",
    "processing_text_3": "Can you become a doll? Sure you Ken!",
    "processing_text_4": "Hey Barbie, where's your Ken?",
    "processing_text_5": "Singing: I'm a Barbie girl in a Barbie world...",
    "processing_text_6": "Hocus pocus! Creating your doll embodiment…",

    "collage__title": "Save and share",
    "collage__text_guest": "This is someone’s Evil Doll, but you could have your own!",
    "collage__tab_image": "Image",
    "collage__tab_video": "Video",
    "collage__tab_0": "Simpsons",
    "collage__tab_1": "Disney",
    "collage__tab_2": "Archer",
    "collage__tab_3": "Vector",
    "collage__tab_4": "Collage",
    "collage__select_photo": "Try with your photo",
    "collage__download": "Save",
    "collage__downloaded": "Photo successfully saved",
    "collage__refresh": "Refresh",
    "collage__share_tag": "Share your result now along with <span class=\"tag\">#</span><span>photolab</span> 🔮",
    "btn-insta-title-post": "Post",
    "btn-insta-title-stories": "Stories",
    "btn-insta-title-android": "share to Instagram",

    "download_modal__title": "Don’t stop here!",
    "download_modal__message": "How does it feel to be a toon star? Share your moment of fame on Instagram by posting the result you like most!",
    "download_modal__message_wait": "Creating the result in HD…",
    "download_modal__message_ready": "HD version is ready, download started…",
    "download_modal__dismiss_button": "Got it!",
    "download_modal__download_button": "Download again",

    "error__error": "Error",

    "internal_error": "An error occurred...",
  },
};

