const STATUS_FAILED = -1;
const STATUS_PENDING = 0;
const STATUS_PROCESSING = 1;
const STATUS_PROCESSED = 2;

export function taskIsProcessed(task) {
  return task.status === STATUS_PROCESSED;
}

export function taskIsFailed(task) {
  return task.status === STATUS_FAILED;
}

export function taskIsProcessing(task) {
  return task.status === STATUS_PENDING || task.status === STATUS_PROCESSING;
}