import * as amplitude from "amplitude-js";

if (window.appConfig.amplitude.isEnabled) {
  amplitude.getInstance().init(window.appConfig.amplitude.key);
  amplitude.getInstance().setUserProperties({
    is_webview: window.clientConfig.isWebview,
    platform_browser_name: window.clientConfig.platform.name,
    platform_browser_version: window.clientConfig.platform.version,
    platform_os: window.clientConfig.platform.os,
  });
}

// --

export const userEvents = {
  // получили событие webiew_tab_selected от нативного приложения
  // параметры:
  // os: android, ios или other
  WEBVIEW_TAB_FOCUS: "webview_tab_focus",

  // визит на главную страницу
  PAGE_INDEX: "page_index",

  // визит на страницу результата
  // параметры:
  // photo_id (наш идентификатор фото)
  // is_owner (владелец/реферал)
  // ref (канал шаринга или unspecified)
  PAGE_RESULT: "page_result",

  // юзер нажал на "попробовать другое фото"
  // параметры: page (страница: create, result, error)
  HOME_BUTTON_CLICK: "home_button_click",

  // юзер выбрал фото
  // параметры: page (страница: index, error, result)
  PHOTO_SELECT: "photo_select",

  // юзер успешно загрузил фото
  PHOTO_UPLOADED: "photo_uploaded",

  // юзер неуспешно загрузил фото
  PHOTO_UPLOAD_FAILED: "photo_upload_failed",

  // успешно закончился процессинг всех обработок
  // elapsed_time_ms (время затраченное пользователем от загрузки фото до события, в миллисекундах)
  PROCESSING_PROCESSED: "processing_processed",

  // неуспешно закончился процессинг всех обработок
  // elapsed_time_ms (время затраченное пользователем от загрузки фото до события, в миллисекундах)
  PROCESSING_FAILED: "processing_failed",

  // юзер сменил таб на результате
  // параметры:
  // group (группа)
  // prev_group (прошлая группа, с которой переключили)
  TAB_SELECT: "tab_select",

  // параметры:
  // group (название группы, для которой сделан рефреш)
  // template_id (только группа vector, идентификатор шаблона, который юзер выбрал)
  TAB_REFRESH: "tab_refresh",

  // юзер кликнул на кнопку шаринга
  // параметры:
  // photo_id (наш идентификатор фото)
  // creative_id (наш идентификатор обработки)
  // provider (название поток шаринга)
  SHARE: "share",

  // юзер кликнул на кнопку скачать
  // параметры:
  // photo_id (наш идентификатор фото)
  // creative_id (наш идентификатор обработки)
  // template_id (наш идентификатор шаблона)
  // group (группа креативов)
  // default_tab_group (группа креативов выбранная при загрузке страницы)
  DOWNLOAD: "download",

  // инициировано скачивание файла
  // параметры: см. параметры события download
  DOWNLOAD_START: "download_start",
};

export const hits = {
  WEBVIEW_TAB_FOCUS: 0,

  PHOTO_SELECT: 0,
  PHOTO_UPLOADED: 0,
  PHOTO_UPLOAD_FAILED: 0,

  PROCESSING_PROCESSED: 0,
  PROCESSING_FAILED: 0,

  SHARE: 0,
  DOWNLOAD: 0,
  DOWNLOAD_START: 0,
};

export function hitEvent(id) {
  if (window.appConfig.isDebug) {
    console.info("hitEvent", id);
  }

  if (window.appConfig.hits.isEnabled) {
    window.axios.post(`${window.appConfig.hits.endpoint}?id=${id}&r=${Date.now()}`)
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logEvent(eventId, eventParams, cb) {
  if (window.appConfig.isDebug) {
    console.info("logEvent", eventId, eventParams);
  }

  logAmplitude(eventId, eventParams, cb);

  if (window.appConfig.analytics.isEnabled) {
    window.axios.post(window.appConfig.analytics.endpoint, {id: eventId, params: eventParams})
      .then(() => {/* dummy */})
      .catch(console.error);
  }
}

export function logAmplitude(eventId, eventParams, cb) {
  if (window.appConfig.amplitude.isEnabled) {
    amplitude.getInstance().logEvent(eventId, eventParams || {}, cb);
  }
}